<template>
  <div class="block">
  <ListCreateUpdateModal v-if="addModalShown" v-bind:add-modal-shown="addModalShown" v-on:newList="addListItem"
                         v-on:updatedItem="updateListItem"
                         v-on:toggleModal="toggleModal" v-bind:item="selectedItem"></ListCreateUpdateModal>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">Vos listes</h1>
        </div>
      </div>

      <div class="level-right">
        <button class="button is-large" v-on:click="newItem">
          <span class="icon is-medium">
            <ion-icon name="add-outline"></ion-icon>
          </span>
        </button>
      </div>
    </nav>
    <div class="columns">
      <div class="column is-one-quarter"  v-for="item in lists" :key="item.id">
        <router-link :to="{ name: 'List', params: { id: item.id }}" custom v-slot="{ navigate }">
          <div class="card" @click="navigate">
            <div class="card-content">
              <p class="title">
                {{item.name}}
              </p>
            </div>
            <footer class="card-footer">
              <a href="#" class="card-footer-item" v-on:click.prevent="selectItem(item)">Edit</a>
              <a href="#" class="card-footer-item" v-on:click.prevent="deleteItem(item)">Delete</a>
            </footer>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ListCreateUpdateModal from "@/components/ListCreateUpdateModal";

export default {
  name: "Lists",
  components: {ListCreateUpdateModal},
  data: function() {
    return {
      lists: [],
      addModalShown: false,
      name: '',
      selectedItem: null
    }
  },
  methods: {
    fetchLists: function () {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        }
      }).then(r => {
        return r.json();
      }).then(result => {
        this.lists = result;
      })
    },
    deleteItem: function (item) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists/` + item.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        }
      }).then(result => {
        if (result.status !== 404) {
          this.lists = this.lists.filter(r => r.id !== item.id);
        }
      });
    },
    toggleModal: function () {
      this.addModalShown = !this.addModalShown;
    },
    newItem: function () {
      this.selectedItem = null;
      this.toggleModal();
    },
    selectItem: function (item) {
      this.selectedItem = item;
      this.toggleModal();
    },
    addListItem: function (item) {
      this.lists.push(item);
    },
    updateListItem: function (id, item) {
      this.lists = this.lists.map(i => {
        if (i.id === id) {
          return item;
        } else {
          return i;
        }
      });
    },
  },
  beforeMount() {
    this.fetchLists();
  }
}
</script>



<style scoped>

.columns {
  flex-wrap: wrap;
}
</style>

<template>
  <div class="modal" v-bind:class="{ 'is-active': addModalShown }">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <div class="field">
          <label class="label">Name
          </label>
          <div class="control">
            <input class="input" type="text" v-model="name" v-on:keyup="createIfEnter">
          </div>
        </div>
        <button class="button is-primary" v-if="item === null" v-on:click="createItem">Create new list</button>
        <button class="button is-primary" v-if="item !== null" v-on:click="updateItem">Update list</button>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" v-on:click="toggleModal"></button>
  </div>
</template>

<script>
export default {
  name: "ListCreateUpdateModal",
  props: ['addModalShown', 'item'],
  data: function() {
    return {
      name: this.item != null ? this.item.name : ''
    }
  },
  methods: {
    toggleModal: function () {
      this.$emit('toggleModal')
    },
    createIfEnter: function (e) {
      if (e.key === 'Enter') {
        this.createItem();
      }
    },
    createItem: function () {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        },
        body: JSON.stringify({name: this.name})
      }).then(r => {
        return r.json();
      }).then(result => {
        this.$emit('newList', result)
        this.toggleModal();
      })
    },
    updateItem: function () {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/lists/` + this.item.id, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': localStorage.getItem('KEY')
        },
        body: JSON.stringify({name: this.name})
      }).then(r => {
        return r.json();
      }).then(result => {
        if (result.status != 404) {
          this.$emit('updatedItem', this.item.id, result)
          this.toggleModal();
        }
      })
    }
  }
}
</script>
